var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-none d-lg-block"
  }, [_c('div', {
    staticClass: "pb-12px"
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("질문유형")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "w-180px",
    attrs: {
      "items": _vm.types,
      "dense": "",
      "placeholder": "선택"
    },
    on: {
      "change": function (value) {
        return _vm.$router.push({
          query: {
            type: value
          }
        });
      }
    },
    model: {
      value: _vm.selectType,
      callback: function ($$v) {
        _vm.selectType = $$v;
      },
      expression: "selectType"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input), false))], 1)], 1)], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "expanded": _vm.expanded,
      "no-data-text": "작성된 상품문의가 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      },
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.summary.totalCount - (_vm.page - 1) * _vm.limit - _vm.items.indexOf(item)) + " ")];
      }
    }, {
      key: `item.state`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.reply ? _c('span', {
          staticClass: "secondary--text"
        }, [_vm._v("답변완료")]) : _c('span', [_vm._v("답변대기")])];
      }
    }, {
      key: `item.subject`,
      fn: function (_ref5) {
        var _item$product, _item$product$thumb;
        var item = _ref5.item;
        return [_c('v-row', {
          staticClass: "ma-n6px text-left",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-6px",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-card', {
          staticClass: "overflow-hidden",
          attrs: {
            "tile": "",
            "outlined": ""
          }
        }, [_c('router-link', {
          attrs: {
            "to": `/shop/products/${item.product._id}`
          }
        }, [_c('v-img', {
          attrs: {
            "src": (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
            "aspect-ratio": 70 / 54,
            "width": "70"
          },
          scopedSlots: _vm._u([{
            key: "placeholder",
            fn: function () {
              return [_c('v-overlay', {
                attrs: {
                  "absolute": "",
                  "opacity": "0.1"
                }
              }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
            },
            proxy: true
          }], null, true)
        })], 1)], 1)], 1), _c('v-col', {
          staticClass: "pa-6px"
        }, [_c('div', {
          staticClass: "txt txt--xs pb-2px"
        }, [_vm._v(_vm._s(item.product.name))]), _c('div', {
          staticClass: "txt txt--sm txt--dark"
        }, [_vm._v(" " + _vm._s(item.subject) + " ")])])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1), _c('div', {
    staticClass: "d-lg-none mx-n12px"
  }, [_c('v-container', {
    staticClass: "pb-12px text-right"
  }, [_c('v-select', {
    staticClass: "border-0 font-size-12 w-100px ml-auto",
    attrs: {
      "items": _vm.types,
      "dense": "",
      "placeholder": "전체보기",
      "append-icon": "mdi-chevron-down",
      "hide-details": "auto"
    },
    on: {
      "change": function (value) {
        return _vm.$router.push({
          query: {
            type: value
          }
        });
      }
    },
    model: {
      value: _vm.selectType,
      callback: function ($$v) {
        _vm.selectType = $$v;
      },
      expression: "selectType"
    }
  })], 1), _vm._l(_vm.items, function (item, index) {
    var _item$product2, _item$product2$thumb;
    return _c('v-card', {
      key: index,
      staticClass: "px-16px py-12px border-b",
      attrs: {
        "to": `./questions/${item._id}`,
        "tile": ""
      }
    }, [_c('div', {
      staticClass: "pb-10px"
    }, [_c('v-row', {
      staticClass: "ma-n6px text-left",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-6px",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      staticClass: "overflow-hidden",
      attrs: {
        "tile": "",
        "outlined": ""
      }
    }, [_c('router-link', {
      attrs: {
        "to": `/shop/products/${item.product._id}`
      }
    }, [_c('v-img', {
      attrs: {
        "src": (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : (_item$product2$thumb = _item$product2.thumb) === null || _item$product2$thumb === void 0 ? void 0 : _item$product2$thumb.url,
        "aspect-ratio": 70 / 54,
        "width": "70"
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('v-overlay', {
            attrs: {
              "absolute": "",
              "opacity": "0.1"
            }
          }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)], 1), _c('v-col', {
      staticClass: "pa-6px"
    }, [_c('div', {
      staticClass: "txt txt--xs pb-2px"
    }, [_vm._v("상품 : " + _vm._s(item.product.name))]), _c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.subject) + " "), _c('v-avatar', {
      staticClass: "font-size-10 white--text",
      attrs: {
        "size": "12",
        "color": "secondary",
        "tile": ""
      }
    }, [_vm._v("N")])], 1)])], 1)], 1), _c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [!item.reply ? _c('v-chip', {
      staticClass: "rounded-sm min-w-80px white--text",
      attrs: {
        "large": "",
        "color": "grey lighten-3"
      }
    }, [_c('span', {
      staticClass: "font-size-12"
    }, [_vm._v("답변대기")])]) : _c('v-chip', {
      staticClass: "rounded-sm min-w-80px white--text",
      attrs: {
        "large": "",
        "color": "secondary"
      }
    }, [_c('span', {
      staticClass: "font-size-12"
    }, [_vm._v("답변완료")])])], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "txt txt--xs"
    }, [_vm._v(_vm._s(item.createdAt.toDate()))])])], 1)], 1);
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        _vm.$router.push({
          query: Object.assign({}, _this.$route.query, {
            page
          })
        });
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('div', {
    staticClass: "pt-md-60px pt-lg-110px d-none d-lg-block"
  }, [_c('inquire-list-search', _vm._b({}, 'inquire-list-search', {
    loading: _vm.loading
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }