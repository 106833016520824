<template>
    <div>
        <!-- S: 게시판(PC) -->
        <div class="d-none d-lg-block">
            <div class="pb-12px">
                <v-row align="center" justify="space-between" class="row--xxs">
                    <v-col cols="auto">
                        <v-row align="center" class="row--xxs">
                            <v-col cols="auto"> <div class="txt txt--sm txt--dark">질문유형</div> </v-col>
                            <v-col cols="auto">
                                <v-select v-model="selectType" :items="types" v-bind="{ ...attrs_input }" dense placeholder="선택" class="w-180px" @change="(value)=> $router.push({query: { type: value }})" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <v-data-table v-bind="{ headers, items, loading }" dense hide-default-footer disable-filtering disable-sort :expanded.sync="expanded" @click:row="(any, { item }) => showBoard(item)" no-data-text="작성된 상품문의가 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.index`]="{ item }">
                    {{ summary.totalCount - (page - 1) * limit - items.indexOf(item) }}
                </template>
                <template #[`item.state`]="{ item }">
                    <span v-if="item.reply" class="secondary--text">답변완료</span>
                    <span v-else>답변대기</span>
                </template>
                <template #[`item.subject`]="{ item }">
                    <v-row align="center" class="ma-n6px text-left">
                        <v-col cols="auto" class="pa-6px">
                            <v-card tile outlined class="overflow-hidden">
                                <router-link :to="`/shop/products/${item.product._id}`">
                                    <v-img :src="item.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-col class="pa-6px">
                            <div class="txt txt--xs pb-2px">{{ item.product.name }}</div>
                            <div class="txt txt--sm txt--dark">
                                {{ item.subject }}
                                <!-- <v-avatar size="12" color="secondary" tile class="font-size-10 white--text">N</v-avatar> -->
                            </div>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </div>
        <!-- E: 게시판(PC) -->
        <!-- S: 게시판(모바일) -->
        <div class="d-lg-none mx-n12px">
            <v-container class="pb-12px text-right">
                <v-select v-model="selectType" :items="types" dense placeholder="전체보기" append-icon="mdi-chevron-down" hide-details="auto" class="border-0 font-size-12 w-100px ml-auto" @change="(value)=> $router.push({query: { type: value }})"/>
            </v-container>
            <!-- 반복 -->
            <v-card v-for="(item, index) in items" :key="index" :to="`./questions/${item._id}`" tile class="px-16px py-12px border-b">
                <div class="pb-10px">
                    <v-row align="center" class="ma-n6px text-left">
                        <v-col cols="auto" class="pa-6px">
                            <v-card tile outlined class="overflow-hidden">
                                <router-link :to="`/shop/products/${item.product._id}`">
                                    <v-img :src="item.product?.thumb?.url" :aspect-ratio="70 / 54" width="70">
                                        <template #placeholder>
                                            <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                        </template>
                                    </v-img>
                                </router-link>
                            </v-card>
                        </v-col>
                        <v-col class="pa-6px">
                            <div class="txt txt--xs pb-2px">상품 : {{ item.product.name }}</div>
                            <div class="txt txt--sm txt--dark font-weight-medium">
                                {{ item.subject }}
                                <v-avatar size="12" color="secondary" tile class="font-size-10 white--text">N</v-avatar>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <v-row justify="space-between" class="row--xxs">
                    <v-col cols="auto">
                        <v-chip v-if="!item.reply" large color="grey lighten-3" class="rounded-sm min-w-80px white--text"><span class="font-size-12">답변대기</span></v-chip>
                        <v-chip v-else large color="secondary" class="rounded-sm min-w-80px white--text"><span class="font-size-12">답변완료</span></v-chip>
                    </v-col>
                    <v-col cols="auto">
                        <div class="txt txt--xs">{{ item.createdAt.toDate() }}</div>
                    </v-col>
                </v-row>
            </v-card>
            <!-- // 반복 -->
        </div>
        <!-- E: 게시판(모바일) -->

        <!-- S: 페이징 -->
        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="(page)=>{ $router.push({ query: {...this.$route.query, page}})}" />
        </div>
        <!-- E: 페이징 -->

        <!-- S: 검색 -->
        <div class="pt-md-60px pt-lg-110px d-none d-lg-block">
            <inquire-list-search v-bind="{ loading }" />
        </div>
        <!-- E: 검색 -->
    </div>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, attrs_input, INQUIRY_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import InquireListSearch from "@/components/client/mypage/inquires/inquire-list-search.vue";

const headers = [
    { width: "8%", text: "번호", value: "index", align: "center" },
    { width: "10%", text: "상태", value: "state", align: "center" },
    // { width: "10%", text: "상품", value: "product", align: "center" },
    { width: "62%", text: "제목", value: "subject", align: "center" },
    { width: "12%", text: "날짜", value: "createdAt", align: "center", formatter: (value) => value?.toDate?.() || value || "-" },
    // { width: "8%", text: "조회", value: "view", align: "center" },
].map((item) => ({ ...item }));

const types = [ { text: "전체보기", value: null }, ...Object.values(INQUIRY_TYPES)];

export default {
    components: {
        ClientPage,
        SubVisualPrimary,
        MypageLayout,
        PageSection,
        TitWrapLine,
        InquireListSearch,
    },
    data: () => ({
        btn_primary,
        btn_primary3,
        attrs_input,
        types,

        selectType: null,
        inquires: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        expanded: [],
    }),
    computed: {
        items() {
            return [...this.inquires];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.inquires = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, inquires } = await api.v1.me.inquires.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.inquires = inquires;
            } finally {
                this.loading = false;
            }
        },

        showBoard(item) {
            this.$router.push({ path: `./inquiries/${item._id}`});
        },
    },
};
</script>

<style lang="scss" scoped></style>
